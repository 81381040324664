import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Box, Column} from 'bloomer'
import "../styles/myStyles.scss"
import { Columns } from "bloomer/lib/grid/Columns"
import { Title } from "bloomer/lib/elements/Title"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="全記事" isRoot={true} />
        <Bio />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <Box key={node.fields.slug} tag="a" href={node.fields.slug}>
              <article key={node.fields.slug}>
                <header>
                  <Columns isMobile={true}>
                    <Column isSize={7}>
                      <Title isSize={6}>{title}</Title>
                    </Column>
                    <Column isSize={5} style={{textAlign: "right"}}>
                      <small>{node.frontmatter.date}</small>
                    </Column>
                  </Columns>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: node.frontmatter.description || node.excerpt,
                    }}
                  />
                </section>
              </article>
            </Box>
          )
        })}
        <hr />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY-MM-DD")
            title
            description
          }
        }
      }
    }
  }
`
